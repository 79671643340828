import React from 'react';
import { ServiceData_1,ServiceData_2} from "./ServiceData";
import "./ServicesStyles.css";

function Services() {
        return(
            <>
            <div className='div-home-services-container'>
                <div className='div-home-services-container-top'>
                    <div className='div-home-services-container-top-h1'>
                        <h1>WHAT WE PROVIDE</h1>
                    </div>  
                    <div className='div-home-services-container-top-p'>
                        <p>We Design, Build, Test and Market within budget and on time.</p>
                    </div>    
                </div>
                <div className='div-home-services-container-bottom'>
                <div className='div-home-services-container-bottom-box-row-1'>  
                <div className='div-home-services-container-bottom-boxes'>
                {
                        ServiceData_1.map((item,index)=>{  
                                    return( 
                                                <div className='div-home-services-container-bottom-boxes-inner'>
                                                    <div className='div-home-services-container-bottom-icon'>
                                                        <i>
                                                            <img src={item.src} className='serviceImg' alt=""/>
                                                        </i>
                                                    </div>   
                                                    <div className='div-home-services-container-bottom-underline'>
                                                        <i className=''/>
                                                    </div>
                                                    <div className='div-home-services-container-bottom-Name'>
                                                        <h3>{item.Name}</h3>
                                                    </div>
                                                    <div className='div-home-services-container-bottom-p'>
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                        
                                    )})
                    }</div></div>
                    <div className='div-home-services-container-bottom-box-row-2'> 
                    <div className='div-home-services-container-bottom-boxes'>
                    {                  
                        ServiceData_2.map((item,index)=>{
                                    return(
                                                <div className='div-home-services-container-bottom-boxes-inner'>
                                                    <div className='div-home-services-container-bottom-icon'>
                                                        <i className={item.icon_cName}/>
                                                    </div>   
                                                    <div className='div-home-services-container-bottom-underline'>
                                                        <i className=''/>
                                                    </div>
                                                    <div className='div-home-services-container-bottom-Name'>
                                                        <h3>{item.Name}</h3>
                                                    </div>
                                                    <div className='div-home-services-container-bottom-p'>
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                    )})
                    }</div>     </div>
                </div>  
            </div>
            </>
        )
    }

export default Services;