import React,{useState} from 'react';
import "./PricingStyles.css";
import { PricingData_1, PricingData_2} from "./PricingData";


function Pricing() {
    const [click1, setClickButton1] = useState(true)
    const [click2, setClickButton2] = useState(false)
    const handleClickButton1 = () => {
        setClickButton1(true);
        setClickButton2(false);
    };   
    const handleClickButton2 = () => {
        setClickButton1(false);
        setClickButton2(true);
    };
        return(
            <>
            <div className='div-pricing-container'>
                <div className='div-pricing-top-container'>
                    <div className='div-pricing-top-container-h1'>
                        <h1>THE RIGHT PLAN FOR YOUR BUSINESS</h1>
                    </div>  
                    <div className='div-pricing-container-top-p'>
                    <p>We know how donting setting up a business could be, so we are here to offer you right price and best quality.</p>
                    </div>    
                        
                </div>
                <div className='div-pricing-container-bottom'> 
                    <div className='div-pricing-bottom-box-row-1'>  
                        <div className={click1? "active":"toggle-button"} onClick={handleClickButton1}>Business Website</div>
                        <div className={click2? "active":"toggle-button"} onClick={handleClickButton2}>E-commerce</div>
                    </div>
                    {click1  &&
                        <div className='div-pricing-bottom-box-row-2'>
                        {
                            PricingData_1.map((item,index)=>{  
                                    return(          
                                            <div className='div-pricing-container-bottom-boxes-inner'>
                                                <div className='div-pricing-container-bottom–Name'>
                                                        <h3>{item.name}</h3>
                                                </div>   
                                                <div className='div-pricing-container-bottom-bullets'>
                                                    <i className='fa fa-check'/> {item.bullet_1}
                                                    <br/>
                                                    <i className='fa fa-check'/> {item.bullet_1} 
                                                    <br/>
                                                    <i className='fa fa-check'/> {item.bullet_1}
                                                    <br/>
                                                    <i className='fa fa-check'/> {item.bullet_1}
                                                    <br/>
                                                    <i className='fa fa-check'/> {item.bullet_1}
                                                </div>
                                                <div className='div-home-services-container-bottom-cost'>
                                                    <h3>{item.Cost}</h3>
                                                </div>
                                            </div>
                                        
                                    )})
                    }
                           
                        </div>
                    }
                    {click2  &&
                        <div className='div-pricing-bottom-box-row-2'>
                        {
                            PricingData_2.map((item,index)=>{  
                                    return(          
                                        <div className='div-pricing-container-bottom-boxes-inner'>
                                        <div className='div-pricing-container-bottom–Name'>
                                                <h3>{item.name}</h3>
                                        </div>   
                                        <div className='div-pricing-container-bottom-bullets'>
                                            <i className=''/>{item.bullet_1} 
                                        </div>
                                        <div className='div-home-services-container-bottom–cost'>
                                            <h3>{item.Cost}</h3>
                                        </div>
                                    </div>
                                        
                                    )})
                    }
                           
                        </div>
                    }
                </div>  
            </div>
            </>
        )
    }

export default Pricing;