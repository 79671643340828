import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../PagesCSS/contactus.css'
import '../common/Button.css'
import '../common/Button.js'

import { Link } from 'react-router-dom';


export default function Contactus() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_o250255', 'template_hzc66ms', form.current, '7fiqqXuD_fD5gQp0z')
        .then((result) => {
            e.target.reset();

            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
  
    return (
        <>
    <div className='contactus-card' id="contactus">
       <div className='div-contactus-container-top'>
            <div className='div-contactus-container-top-h1'>
                <h1>CONTACT US</h1>
            </div>    
        </div>
        <div className='parent'>
             <div className='child_1'>
                <div className='contactus_info_top'>
                    <div className='contactus_info_top_line1'>
                        <h2>Talk to us about everything!</h2>
                    </div>
                    <div className='contactus_info_top_line2'>
                        <h4>you can talk to us when ever you want bithes beacuse i want money</h4>
                    </div>
                </div>
                <div className='contactus_info_center'>
                    <div className='contactus_info_center_div'> 
                        <div className='contactus_info_center_icon'>
                            <i class='fa fa-phone fa-lg' />
                        </div>
                            <div className='contactus_info_center_icon_data'>
                                <h4>+(91) 9920711692</h4>
                            </div>
                    </div>
                    <div className='contactus_info_center_div'>
                        <div className='contactus_info_center_icon'>
                            <i class="fa fa-envelope fa-lg" />
                        </div>
                        <div className='contactus_info_center_icon_data'>
                            <h4>neha.thak16@gmail.com</h4>
                        </div>
                    </div>
                    <div className='contactus_info_center_div'>
                        <div className='contactus_info_center_icon'>
                            <i class="fa fa-map-marker fa-lg"/>
                        </div>
                        <div className='contactus_info_center_icon_data'>
                            <h4>Mumbai, India</h4>
                        </div>
                    </div>
                </div> 
                <div className='contactus_info_bottom'>
                    
                            <Link
                            className='social_media_link'
                            to='/'
                            target='_blank'
                            aria-label='Instagram'
                            >
                            <i class='fa fa-instagram fa-lg' />
                            </Link>
                            <Link
                            className='social_media_link'
                            to='/'
                            target='_blank'
                            aria-label='Twitter'
                            >
                            <i class='fa fa-twitter fa-lg' />
                            </Link>
                            <Link
                           className='social_media_link'
                            to='/'
                            target='_blank'
                            aria-label='LinkedIn'
                            >
                            <i class='fa fa-linkedin fa-lg' />
                            </Link>
         
                </div>
            </div>
            <div className='child_2'>
            <div className='contactus_child_2_form'>
                <form ref={form} onSubmit={sendEmail} className='contactus__items'>
                    <div className='form-controm-top-margin'>
                        <input type="text" name="user_name" className='form-control_item' placeholder='Name' />
                        <input type="email" name="user_email" className='form-control_item' placeholder='Email'/>
                        <textarea name="message" className='form-control_item form-control-textarea' cols="20" rows="5" placeholder='Write a message'/>
                    </div>
                    <div className='form-controm-bottom-margin'>
                        <input type="submit" value="Send" className='form-control-button'/>
                    </div>
                </form>
            </div>
            </div>
        </div>






    </div>

    </>

    );
  };
