import React from 'react';
import './TopSection.css';
import { Link } from 'react-scroll';


function HeroSection() {
  return (
    <div className='hero-container' id="topsection" >

        <div className='hero-container-top'>
          <div className='hero-container-top-left'>
            <h1>We build technology in the human flourishing space</h1>
            <Link to="contactus" 
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  className='hero-container-button-div'>
                    <button
                      className="hero-container-button">
                      Free Consultation
                    </button>
            </Link>
          </div>
          <div className='hero-container-top-right'>
          <img src='/images/25078024_flat_isometric_illustration_landing_page_concept_developer_application_web_design_layout.jpg' alt="op"/>
          </div>
        </div>
    <div className='hero-parent-container-div'>
        <div className='parent-hero-title'>
          <h3>
            why Intellectus
          </h3>
        </div>
        <div className='parent-hero-paragraph'>
          <p>
              we offer extensive expertise, comprehensive services, and a commitment to quality to meet your unique software development and testing needs.
          </p>
        </div>
        <div className='parent-hero-div'>
            <div className='child-hero-div'>
            <img src='/images/headset.png' alt="24-7_Support"/>
              <h4>24/7 Support</h4>
            </div>
            <div className='child-hero-div'>
              <img src='/images/on-time.png' alt="on-time"/>
              <h4>On Time Delivery</h4>
            </div>
            <div className='child-hero-div'>
              <img src='/images/personal-computer.png' alt="personal-computer"/>
              <h4>Responsive Websites</h4>
            </div>
            <div className='child-hero-div'>
              <img src='/images/customised-solution.png' alt="customised-solution"/>
              <h4>Customised Solution</h4>
            </div>
            <div className='child-hero-div'>
              <img src='/images/tag.png' alt="SEO-Friendly-Websites"/>
              <h4>SEO Friendly Websites</h4>
            </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;