export const ServiceData_1 =[
    {
        "icon_cName":"fa fa-cloud",
        "Name":"Web and App Development",
        "paragraph":"A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality",
        "src":"/images/coding.png"
    },
    {
    "icon_cName":"fa fa-cloud",
    "Name":"E-commerce Website",
    "paragraph":"A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality",
    "src":"/images/laptop.png"
    },
    {
    "icon_cName":"fa fa-cloud",
    "Name":"DevOps",
    "paragraph":"A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality",
    "src":"/images/devops.png"
    },]
export const ServiceData_2 =[
        {
            "icon_cName":"fa fa-cloud",
            "Name":"Managed Cloud services",
            "paragraph":"A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality",
        },
        {
            "icon_cName":"fa fa-cloud",
            "Name":"QA and Testing",
            "paragraph":"A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality",
        },
        {
        "icon_cName":"fa fa-cloud",
        "Name":"Data and AI Services",
        "paragraph":"A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality",
        }]