import React from 'react';
import '../../App.css'
import TopSection from '../section/TopSection'
import Services from '../../component/Services/Services'
import HowWeWork from '../../component/HowWeWork/HowWeWork'
import Pricing from '../../component/Pricing/Pricing'
import Contactus from './Contactus';
import Testimonial from '../Testimonial/Testimonial';


function Home() {
    return (
      <>
      <div >
        <TopSection />
        <Services/>
        <HowWeWork/>
        <Pricing/>
        <Contactus/>
        <Testimonial/>
       </div>
      </>
    );
  }
  
  export default Home;