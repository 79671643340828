export const PricingData_1 =[
    {
        "name":"Basic",
        "bullet_1":"gafag",
        "Cost":"$10"
    },
    {
        "name":"Classic",
        "bullet_1":"gafag",
        "Cost":"$10"
    },
    {
        "name":"Premium",
        "bullet_1":"gafag",
        "Cost":"$10"
    },
    {
        "name":"Custome",
        "bullet_1":"gafag",
        "Cost":"$10"
    }
]

export const PricingData_2 =[
    {
        "name":"Basic",
        "bullet_1":"gafag",
        "Cost":"10"
    },
    {
        "name":"Classic",
        "bullet_1":"gafag",
        "Cost":"10"
    },
    {
        "name":"Premium",
        "bullet_1":"gafag",
        "Cost":"10"
    },
    {
        "name":"Custome",
        "bullet_1":"gafag",
        "Cost":"10"
    }
]