export const MenuData =[
    {
        "title":"Home",
        "url":"#",
        "cName":"nav-links",
    },
    {
        "title":"Services",
        "url":"#",
        "cName":"nav-links",
    },
    {
        "title":"Testimonial",
        "url":"#",
        "cName":"nav-links",
    },
    {
        "title":"Blogs",
        "url":"#",
        "cName":"nav-links",
    },
    {
        "title":"Contact-us",
        "url":"#",
        "cName":"nav-links-mobile",
    }
]