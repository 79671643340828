import React, { useState} from 'react';
import { MenuData } from "./MenuData";
import "./NavbarStyles.css";

function Navbar() {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click);

        return(
            <>
            <nav className="NavbarItems">
                <h1 className="logo">Arccos <i className="fa fa-react"></i></h1>
                <div className="menu-icons" onClick={handleClick}> 
                    <i className={click ? 'fa fa-times' : 'fa fa-bars'}/>
                </div>
                <ul className={click? "nav-menu active":"nav-menu"}>
                    {
                        MenuData.map((item,index)=>{
                            return(                                
                            <li key={index}>
                                <a href={item.url} className={item.cName}>
                                    {item.title}
                                </a>
                            </li>
                            )
                        })
                    }
                </ul>
            </nav>
            </>
        )
    }

export default Navbar;