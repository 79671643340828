import React from 'react';
import { Avatar } from "@material-ui/core";


function TestimonialCardItems(props) {
  return (
    <>
       <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={props.img}
        style={{
          width: 120,
          height: 120,
          border: "1px solid lightgray",
          padding: 7,
          marginBottom: 20,
        }}
      />
      <p>
        {props.review}
      </p>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "green" }}>{props.name}</span> ,
        {props.occupation}
      </p>
    </div>
    </>
  );
}

export default TestimonialCardItems;