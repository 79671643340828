import React from 'react';
import "./HowWeWorkStyles.css";

function HowWeWork() {
        return(
            <>
            <div className='div-home-how-we-work-container'>
                <div className='div-home-how-we-work-container-top'>
                    <div className='div-home-how-we-work-container-top-h1'>
                        <h1>HOW WE WORK</h1>
                    </div>  
                    <div className='div-home-how-we-work-container-top-p'>
                        <p>We Design, Build, Test and Market within budget and on time.</p>
                    </div>    
                </div>
                <div className='div-home-how-we-work-container-bottom'>
                <div className='div-home-how-we-work-container-bottom-box-row-1'>  
                    <div className='div-home-how-we-work-container-bottom-boxes'>
                        <div className='div-home-how-we-work-container-bottom-boxes-inner_1'>
                                <div className='div-home-how-we-work-container-bottom-icon'>
                                    <i className="fa fa-cloud"/>
                                </div>   
                                <div className='div-home-how-we-work-container-bottom-underline'>
                                    <i className=''/>
                                    </div>
                                 <div className='div-home-how-we-work-container-bottom-Name'>
                                    <h3>Website Design</h3>
                                </div>
                                <div className='div-home-how-we-work-container-bottom-p'>
                                    <p>A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality</p>
                                </div>
                        </div>               
                
                        <div className='div-home-how-we-work-container-bottom-boxes-inner_2'>
                                <div className='div-home-how-we-work-container-bottom-icon'>
                                    <i className="fa fa-cloud"/>
                                </div>   
                                <div className='div-home-how-we-work-container-bottom-underline'>
                                    <i className=''/>
                                    </div>
                                 <div className='div-home-how-we-work-container-bottom-Name'>
                                    <h3>Website Design</h3>
                                </div>
                                <div className='div-home-how-we-work-container-bottom-p'>
                                    <p>A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality</p>
                                </div>
                        </div> 

                        <div className='div-home-how-we-work-container-bottom-boxes-inner_3'>
                                <div className='div-home-how-we-work-container-bottom-icon'>
                                    <i className="fa fa-cloud"/>
                                </div>   
                                <div className='div-home-how-we-work-container-bottom-underline'>
                                    <i className=''/>
                                    </div>
                                 <div className='div-home-how-we-work-container-bottom-Name'>
                                    <h3>Website Design</h3>
                                </div>
                                <div className='div-home-how-we-work-container-bottom-p'>
                                    <p>A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality</p>
                                </div>
                        </div>             

                        <div className='div-home-how-we-work-container-bottom-boxes-inner_4'>
                                <div className='div-home-how-we-work-container-bottom-icon'>
                                    <i className="fa fa-cloud"/>
                                </div>   
                                <div className='div-home-how-we-work-container-bottom-underline'>
                                    <i className=''/>
                                    </div>
                                 <div className='div-home-how-we-work-container-bottom-Name'>
                                    <h3>Website Design</h3>
                                </div>
                                <div className='div-home-how-we-work-container-bottom-p'>
                                    <p>A well-designed website should be easy to navigate, visually appealing, and function properly across different devices We give importance to site maps, wireframes, prototypes and Testing to deliver 100% Quality</p>
                                </div>
                        </div>             

                    </div>
                </div>          
                </div>  
            </div>
            </>
        )
    }

export default HowWeWork;