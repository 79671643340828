import React from 'react';
import './App.css';
import Navbar from '../src/component/Navbar'
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from './components/Pages/Home';

import Footer from './components/Footer/Footer';

function App() {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' exact Component={Home}/>
      </Routes>
      <Footer/>
    </Router>
    </>


  );
}

export default App;
