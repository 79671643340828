import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import TestimonialCardItems from "./TestimonialCardItems";

const PreviousBtn = (props) => {
    console.log(props);
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
      </div>
    );
  };

  const Testimonial = () => {
    return (
        <div className='testimonial-card' id="testimonial">
          <div className='div-testimonial-container-top'>
            <div className='div-testimonial-container-top-h1'>
                <h1>Testimonial</h1>
            </div>    
        </div>
            
            <div
                className="testimonial"
                style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
            >
            

                <div style={{ width: "50%", textAlign: "center" }}>
                <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
                    <TestimonialCardItems 
                        img=""
                        review="abcds i ahgahdahjgadjghadb jagdkjadakjdkja agdjadgjh"
                        name="tayta"
                        occupation="abvc"
                    />


                    <TestimonialCardItems 
                        img="" 
                        review="abcds i ahgahdahjgadjghadb jagdkjadakjdkja agdjadgjh"
                        name="tayta"
                        occupation="abvc"
                    />

                    <TestimonialCardItems 
                        img="" 
                        review="abcds i ahgahdahjgadjghadb jagdkjadakjdkja agdjadgjh"
                        name="tayta"
                        occupation="abvc"
                    />
                </Slider>
                </div>
            </div>
        </div>
    );
  };

  export default Testimonial;